import React from 'react'
import { useTranslation } from 'react-i18next'
import TranslateIcon from '@mui/icons-material/Translate';
import { Button, Menu, MenuItem } from '@mui/material';

const ChooseLng = () => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };

    const {t,i18n} = useTranslation();

    const handleChange = (lang) => {
        i18n.changeLanguage(lang);
        handleClose();
    }
    const handleClose = () => {
        setAnchorEl(null);
        console.log('ran')
    }

  return (
    <><Button 
    aria-controls={open ? 'basic-menu' : undefined}
    aria-haspopup="true"
    aria-expanded={open ? 'true' : undefined}
    variant='contained' 
    onClick={handleClick}
    style={{borderRadius:'50px',textTransform: 'none'}}
    >
          <TranslateIcon /> {t('changelanguage')}
      </Button>
      <Menu 
        onClose={handleClose} 
        anchorEl={anchorEl}
        open={open}>
              <MenuItem onClick={() => handleChange('nb')}>Norsk</MenuItem>
              <MenuItem onClick={() => handleChange('en')}>English</MenuItem>
          </Menu>
    </> 
  )
}

export default ChooseLng