import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
const resources = {
  en: {
    translation: {
        "changelanguage": "Language",
        'welcome': 'Welcome to Solvegen',
        'intro': 'This is an interactive web weather map for planning a travel route. The special element about this weather map is that the weather shown is the weather for the estimated arrival time. In other words, the weather map tries to show the weather you will get along the route if you drive it.',
        'userManualTitle' : 'Introduction:',
        'howToStart': 'You start by clicking somewhere on the map. A green marker will then appear. This will be your starting point.',
        'howToCompleteRoute': 'The second time you click on the map, the end point will appear and a suggested route will be shown on the map.',
        'movePoints': 'Both the start and end point can be dragged around to adjust the route. The route will be updated when you release the point with the mouse pointer.',
        'howToRestart':'If you want to start again, you can double-click somewhere and the start point will be moved there, while the end point will be removed.',
        'howToExploreOtherRoutes': 'To explore alternative routes between start and stop, extra points can be added. This is done by clicking somewhere on the route. Then a new point will appear. This point can be dragged around to create new routes, without moving the start and stop.',
        'adjustStartTime': 'You can adjust your start time by using the slider at the top right. When this is adjusted, the arrival time of the locations along the route will change and perhaps a different weather symbol will be displayed.',
        'chooseStartTime': 'Choose start time',
        'now': 'Now',
      }
  },
  nb: {
    translation: {
      "changelanguage": "Språk",
      'welcome': 'Velkommen til Solvegen',
      'intro': 'Dette er et interaktivt værkart for ruteplanlegging. Det speesielle med dette værkartet er at været som vises er været for estimert passeringstid. Værkartet prøver med andre ord å vise det været du får hvis du kjører ruta.',
      'userManualTitle' : 'Introduksjon:',
      'howToStart': 'Du starter ved å klikke et sted på kartet. Da vil det dukke opp en grønn markør. Dette vil være startpunktet ditt.',
      'howToCompleteRoute': 'Den andre gangen du trykker på kartet vil endepunktet dukke opp og en forelått rute vil bli vist på kartet. ',
      'movePoints': 'Både start- og endepunkt kan trekkes rundt for å justere ruta. Ruta vil bli oppdatert når du slipper punktet med musepekeren',
      'howToRestart':'Hvis du ønsker å starte på nytt kan du dobbeltklikke et sted og start vil bli flyttet dit, mens endepunktet vil bli fjernet.',
      'howToExploreOtherRoutes': 'For å utforske alternatvie ruter mellom start og stopp kan ekstra punkt legges til. Dette gjøres ved å klikke et sted på ruta. Da vil et nytt punkt dukke opp. Dette punktet kan trekkes rundt for å lage nye ruter, uten at start og stopp flyttes.',
      'adjustStartTime': 'Du kan justere starttida din ved å bruke skyveknappen oppe til høyre. Når denne justeres vil ankomsttiden til passeringsstedene langs ruta forandre seg og kanskje vil en annen værmelding vises.',
      'chooseStartTime': 'Velg starttid',
      'now': 'Nå',
    }
  },
  
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    lng: 'nb',
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
  });

export default i18n;