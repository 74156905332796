import React,{useEffect,useState} from 'react'
import Weather from './Weather';
import along from "@turf/along"
import { saveAs } from "file-saver";
import {coordToStringOrigin, coordsToString, coordsToStringDestination} from "../lib/coordsToString.js"
import { getDurations } from '../lib/distanceMatrixUtil.js';
import { usePoints } from './PointsContext.js';
const ACCSESS_TOKEN = 'pk.eyJ1IjoibGFmaXNlciIsImEiOiJja2dwcmlhaW8wc3h1Mndtb2VtOXplMWp0In0.Vi0BWSGA2uPlDSbm2tb9zQ'
const DISTANCE_API = 'etSJHxVFMleDF0wRWcC73B92e5I0QMsLq0XAvkYSI3D7D0EhAfZv3cArn2lChLAH'
async function directionsQuery(coords){
  const coordsString = coordsToString(coords);

  const query = await fetch(
    `https://api.mapbox.com/directions/v5/mapbox/driving-traffic/${coordsString}?steps=true&geometries=geojson&overview=full&access_token=${ACCSESS_TOKEN}`,
    { method: 'GET' }
  );
  return await query.json();
  
}
async function directionsMatrixQuery(coords){
  const coordsString = coordsToString(coords);
  const query = await fetch(
    `https://api.mapbox.com/directions-matrix/v1/mapbox/driving-traffic/${coordsString}?annotations=distance,duration&access_token=${ACCSESS_TOKEN}`,
    { method: 'GET' }
  );
  return await query.json();
  
}

async function distanceMatrix(coords){
  const origin = coordToStringOrigin(coords[0]);
  const destinations = coordsToStringDestination(coords);
  const query = await fetch(
    `https://api.distancematrix.ai/maps/api/distancematrix/json?origins=${origin}&destinations=${destinations}&key=${DISTANCE_API}`,
    { method: 'GET' }
  )
  return await query.json();
}

function Route(props) {
    const [points,setPoints] = useState([]);
    const [durations,setDurations] = useState([0])
    const [route,setRoute] = useState([]);
    const map = props.map;
    let change = props.change
    let startTime = props.startTime;
    let firstAndLastFirst = usePoints()//props.firstAndLastFirst;
    let time = [0];
    
    
    useEffect(() => {
      
      async function fetchData(){
        
        if(firstAndLastFirst.length === 0){
          firstAndLastFirst= [{lng:24,lat:9},{lng:24,lat:9}]
        }
        if(firstAndLastFirst.length === 1){
          firstAndLastFirst= [firstAndLastFirst[0],firstAndLastFirst[0]]
        }

        if(firstAndLastFirst.length >1&&map){
          const cc = map.getContainer();
          const els = cc.getElementsByClassName('marker');
          const arr = Array.prototype.slice.call( els )
          
          for(let item of arr){
            item.remove();
          }
          let wayPoints = JSON.parse(JSON.stringify(firstAndLastFirst));
            const last = wayPoints[1]
            wayPoints.splice(1,1)
            wayPoints.push(last); 
           
            const json = await directionsQuery(wayPoints);
            // saveAs(
            //       `data:text/json;charset=utf-8,${encodeURIComponent(
            //           JSON.stringify(json)
            //         )}`,
            //       `cali`+'.geojson'
            //   )
  
            const data = json.routes[0];
            const route = data.geometry.coordinates;
            setRoute(route);
            let hoveredStateId = null;
        
            const geojson =  {
              type: 'FeatureCollection',
              features: [
                {
                  type: 'Feature',
                  properties: {},
                  id : 1,
                  geometry: {
                    type: 'LineString',
                    coordinates: route
                  }
                }
              ]
            };
            if (map.getSource('route')) {
              map.getSource('route').setData(geojson);
            }
            // otherwise, we'll make a new request
            else {
              map.addLayer({
                id: 'route',
                type: 'line',
                source: {
                  type: 'geojson',
                  data: geojson
                },
                layout: {
                  'line-join': 'round',
                  'line-cap': 'round'
                },
                paint: {
                  'line-color': '#3887be',
                  'line-width': 6,
                  'line-opacity':  [
                    'case',
                    ['boolean', ['feature-state', 'hover'], false],
                    0.6,
                    0.8
                    ]
                }
              });
              map.on('mousemove', 'route', (e) => {
                
                if (e.features.length > 0) {
                if (hoveredStateId !== null) {
                map.setFeatureState(
                { source: 'route', id: hoveredStateId },
                { hover: false }
                );
                }
                
                hoveredStateId = e.features[0].id;
                map.setFeatureState(
                { source: 'route', id: hoveredStateId },
                { hover: true }
                );
                }
                });
                 
                // When the mouse leaves the state-fill layer, update the feature state of the
                // previously hovered feature.
                map.on('mouseleave', 'route', () => {
                if (hoveredStateId !== null) {
                map.setFeatureState(
                { source: 'route', id: hoveredStateId },
                { hover: false }
                );
                }
                hoveredStateId = null;
                });
            };
    
            
            const km = data.distance/1000
          
            const numberOfPoints = Math.round(10*(1-Math.exp(-0.0015*km))) //fakstisk hvor mange linjestykekr
    
            const distanceBetweenPoints = Math.floor(km/numberOfPoints); 
            points.splice(0,points.length)
            for(let i=0;i<km;i++){
              if(i%distanceBetweenPoints===0){
                let point = along(geojson.features[0],i);
                let inter = point.geometry.coordinates
                let coords = {lng:inter[0],lat:inter[1]}
                points.push(coords)
                setPoints(points)
              }
            }
            
            if(points.length>=2){
                // const distanceAlong = await distanceMatrix(points);
                time = [0]//getDurations(distanceAlong);//[0]
                // console.log(distanceAlong);
                // console.log(time);
                const along = await directionsMatrixQuery(points)   
                for (let i=1;i<points.length;i++){
                  time.push(along.durations[i-1][i]+time[i-1])
                  
                }
                setDurations(time)
              }
            
            // if the route already exists on the map, we'll reset it using setData
            
        }
      }
      fetchData();
      console.log(change)
    }, [firstAndLastFirst.length,change,map])
    


  return (
    <Weather
    map = {map}
    startTime = {startTime}
    points = {points}
    durations = {durations}
    route = {route}
    />
  )
}

export default Route