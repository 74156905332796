import React, { useEffect, useState } from 'react'
import  Slider  from '@mui/material/Slider';
import { usePoints } from './PointsContext';
import { IconButton } from '@mui/material';
import HelpIcon from '@mui/icons-material/Help';
import { useTranslation } from 'react-i18next';

const styles = {
  height: '10vh',
  width: 'calc(100%-30px)',
  display: "flex",
  alignItems: 'center',
  paddingLeft: "5vw",
  fontSize: '38px',
  fontWeight: 'bold',
  color: '#FFFFFF',
  backgroundColor: '#AAAAAA',
}

const sliderStyle = {
  top:'1vh',
  position: "relative",
  width: '400px',
}
const pStyle = {
  padding:'2vw',
  fontSize: '18px',
  position: "relative",
  color: '#333333',

  
}
const pTime ={
  padding:'2vw',
  fontSize: '18px',
  position: "relative",
  color: '#333333',
  width: '3vw',
}

const pos ={
  justifyContent: 'space-between',
  marginLeft:'auto',
  marginRight:'7vw',
  display: "flex",
  position: "relative",
  alignItems: 'center',
}

const Header = (props) => {
  const {t} = useTranslation();
  const [despayedTime, setDesplayedTime] = useState(`${t('now')} `)
  const points  = usePoints()
  
  useEffect(() =>{
    setDesplayedTime(`${t('now')}`)
  },[t])
  
  const times = [new Date(),new Date(),new Date(),new Date(),new Date(),new Date()]
  for(let i=0;i<times.length ;i++){
    times[i].setHours(times[i].getHours() + i*6)
  }

  // useEffect(() => {
  //   findNewDesplayedTime(0)
  // },[]);

const marks = [
  {
    value: 0,
    label: `${t('now')} `,
  },
  {
    value: 6,
    label: `${times[1].getHours()}:00 `,
  },
  {
    value: 12,
    label: `${times[2].getHours()}:00 `,
  },
  {
    value: 18,
    label: `${times[3].getHours()}:00 `,
  },
  {
    value: 24,
    label: `${times[4].getHours()}:00 `,
  },
  {
    value: 30,
    label: `${times[5].getHours()}:00 `,
  },
];

  const handleCange = (event,newValue)=>{
    props.setStartTime(newValue);
    findNewDesplayedTime(newValue);
  }

  const findNewDesplayedTime = (time) => {
    const newTime =   (time + times[0].getHours())%24;
    const newTimeString = newTime <= 9 ? `0${newTime}:00` : `${newTime}:00`;
    if(time === 0){
      setDesplayedTime(`${t('now')}`)
    } 
    else {
      setDesplayedTime(newTimeString);
    }
    
  }

  const handleClick = () =>{
    props.setVisited(false);
  }

  return (
      <div style={styles}>
          <header>Solvegen </header>
          <div style={pos}>
            <p style={pStyle}>{t('chooseStartTime')}</p>
            <Slider
              
              id="slider"
              style = {sliderStyle}
              aria-label="Custom marks"
              defaultValue={0}
              onChange={handleCange}
              step={1}
              max={30}
              marks={marks}
          />
          <p style={pTime}>{despayedTime}</p>
         </div>
         <IconButton style={{margin:'50px'}} onClick={handleClick}>
            <HelpIcon/>
         </IconButton>
      </div>
  )
}


export default Header;