export function coordsToString(coords){//Formatert for mapbox API spørring
    var coordsString = ''
    for(let i=0;i<coords.length;i++){
      if (i=== (coords.length-1)){
        coordsString = coordsString + `${coords[i].lng},${coords[i].lat}`
      }
      else{
        coordsString = coordsString + `${coords[i].lng},${coords[i].lat};`
      }
      
    }
    return coordsString;
}

export function coordToStringOrigin(coord){
  return `${coord.lat},${coord.lng}`
}

export function coordsToStringDestination(coords){
  let coordsString = '';
  for(let i=1; i<coords.length; i++){
    if (i=== (coords.length-1)){
      coordsString = coordsString + `${coords[i].lat},${coords[i].lng}`
    }
    else{
      coordsString = coordsString + `${coords[i].lat},${coords[i].lng}|`
    }
  }
  return coordsString;
}