import React, { useContext, useState } from "react";

const PointsContext = React.createContext();
const PointsUpdateContext = React.createContext();

export function usePoints() {
    return useContext(PointsContext);
}

export function usePointsUpdate(){
    return useContext(PointsUpdateContext);
}


export function PointsProvider({children}){
    const [points, setPoints] = useState([])

    function updatePoints(points) {
        setPoints(points)
    }


    return(
        <PointsContext.Provider value={points}>
            <PointsUpdateContext.Provider value={updatePoints}>
                {children}
            </PointsUpdateContext.Provider>
        </PointsContext.Provider>
    );

}