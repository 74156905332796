import React, { useEffect, useRef, useState} from "react";
// eslint-disable-next-line import/no-webpack-loader-syntax
import mapboxgl from "!mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";
// eslint-disable-next-line import/no-webpack-loader-syntax
import MapboxWorker from "worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker";



import {addPoint} from "../lib/locationSelection"
import {point} from "../lib/point"

import Route from "./Route";
import { usePoints, usePointsUpdate } from "./PointsContext";




const ACCSESS_TOKEN = 'pk.eyJ1IjoibGFmaXNlciIsImEiOiJja2dwcmlhaW8wc3h1Mndtb2VtOXplMWp0In0.Vi0BWSGA2uPlDSbm2tb9zQ'

    
  const styles = {
    width: "100%",
    height: "90vh",
    position: "absolute"
  };


 
const MapboxGLMap = ((props) => {
  const [map, setMap] = useState(null);
  const points = usePoints();
  const updatePoints = usePointsUpdate();
  const [change,setChange] = useState(false);
  const mapContainer = useRef(null);


  let startTime = props.startTime;
  let cha = true;
  
  
  useEffect(() => {
    mapboxgl.accessToken = ACCSESS_TOKEN;

    function routeClick(event,map){
    
      const coords = event.lngLat;
      if(points.length>=2){   
        points.push(coords)     
        updatePoints(points)
        addPoint(points,points.length-1,map);
      }

  }
  async function pointClick(event,map){
    
      const coords = event.lngLat;
      
      if(points.length<=1){
        points.push(coords)
        updatePoints(points)
        addPoint(points,points.length-1,map);
        cha = !cha
        setChange(cha);
      }
      if(points[0] === points[points.length-1]&&points.length>=2){
        map.getSource(`point${points.length -1}`).setData(point(coords));
        points[points.length-1] = coords;
      }
      
      
      
  }
  async function doubbleClick(event,map){
    
      const coords = event.lngLat;
      
      if(points.length>=2){
        for(let i = 0;i<1; i++){
          map.getSource(`point${i}`).setData(point(coords));
          
          points[i] = coords;
        }
        for(let i = 1;i<points.length; i++){
          if (map.getLayer(`point${i}`)) {
          map.removeLayer(`point${i}`)
          map.removeSource(`point${i}`)
          }
        }
        
        points.splice(1,points.length-1)
        updatePoints(points);
        
        setChange(!change);
      }
      
      
      
    
  }
    
    if(!map) {
      const map = new mapboxgl.Map({
        container: mapContainer.current,
        style: "mapbox://styles/lafiser/clbfm8u7i002514m54cn932jp", // stylesheet location
        center: [10.408773,63.422091],
        projection: {
          name: 'mercator',
         
          },
        zoom: 6
      });
        
      
        
        map.on("load", async () => {
          setMap(map);
           map.resize();
           map.on('click',  (event) => {pointClick(event,map)})
           map.on('click','route', (event)=>{routeClick(event,map)})
           map.on('dblclick', (event)=>{doubbleClick(event,map)});
           for(let i = 0;i<20;i++){
            // eslint-disable-next-line no-loop-func
            map.on('mousedown', `point${i}`, () => {
              map.once('mouseup', () =>{
                cha = !cha
                setChange(cha);
              })
              });
           }
           
        });
        // map.on('zoom', ()=>{
        //   const cc = map.getContainer();
        //   const els = cc.getElementsByClassName('marker');
        //   const arr = Array.prototype.slice.call( els )
          
        //   for(let item of arr){
        //       //const offset = item.getOffset()
        //       console.log(item.attributes)
        //   }
        // });
        
    };

    
  },[map]);
  
  



  return (
    <div>
      <div ref={(el) => (mapContainer.current = el)} style={styles} />
      <Route
      map = {map}
      startTime ={startTime}
      firstAndLastFirst = {points}
      change = {change}
      />
      
    </div>
  );
});

export default MapboxGLMap;