import {React,useState} from 'react'
import Header from './Header';
import Map from './Map';
import Menu from './Menu';
import { PointsProvider } from './PointsContext';



function MainPage(props) {
    const [start,setStart] = useState(undefined)   

    const [end,setEnd] = useState(undefined)
    const [startTime,setStartTime] = useState(0)


    

    return (
        <div>
        <PointsProvider>
        <Header 
        setVisited = {props.setVisited}
        setStart ={ setStart}
        setEnd = {setEnd}
        time = {startTime}
        setStartTime = {setStartTime}
        />
        {/* <Menu/> */}
        <Map 
        start = {start}
        end = {end}
        setStart ={setStart}
        setEnd = {setEnd}
        startTime = {startTime}
        />
        </PointsProvider>
        </div>
    )
}

export default MainPage