import { useEffect, useState } from 'react';
import './App.css';
import MainPage from './components/MainPage';
import InfoPage from './components/InfoPage';


function App() {
  const [visited, setVisited] = useState(true);

  useEffect(()=>{
    const  checkVisited = localStorage['visited']
    if (checkVisited === 'true'){
      setVisited(true);
    } else {
      setVisited(false);
      localStorage['visited'] = true;
    }
  },[])

  return (
    <>{visited ?  null :
    <InfoPage 
    setVisited = {setVisited}
    /> }
    <MainPage 
    setVisited = {setVisited}
    />
    </>
  );
}

export default App;
