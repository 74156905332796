import React from 'react'
import CloseIcon from '@mui/icons-material/Close';
import { IconButton } from '@mui/material';
import { useTranslation } from 'react-i18next';
import ChooseLng from './ChooseLng';
import start from '../assets/png/start.png'; 
import stop from '../assets/png/stopp.PNG'; 
import intermediate from '../assets/png/intermediate.PNG'
import exampleRoute from '../assets/png/example.PNG'


const style = {
    textAlign: 'center',
    position: 'absolute',
    width: 'calc(100vw - 200px)',
    height: 'calc(100vh - 200px)',
    marginTop: '150px',
    marginLeft: '100px',
    borderRadius: '30px',
    zIndex: '10',
    backgroundColor: 'rgba(255,255,255,0.6)',
}

const clickBlock = {
    position: 'absolute',
    width: '100vw',
    height: '100vh',
    zIndex: '9',
    backgroundColor: '#000000',
    opacity: '0.5',
}

const infoBox = {
    position: 'relative',
    maxHeight:'80%',
    overflowY:'auto',
    textAlign: 'center',
    marginTop:'3%',
    color:'black',
    fontFamily: 'Arial, Helvetica, sans-serif',
    scrollbarWidth: 'thin'
}

const intro = {
    position: 'relative',
    maxWidth: '700px',
    minWidth: '200px',
    marginLeft:'auto',
    marginRight:'auto',
    paddingLeft: '150px',
    paddingRight: '150px',
    maxHeight:'90%',
}

const InfoPage = (props) => {
    const {t} = useTranslation();

    const closeInfo = () => {
        props.setVisited(true);
    }

  return (
    <><div style={clickBlock}>
    </div>
      <div style={style}>
        
        <IconButton aria-label='close-info' style={{float: 'right'}} onClick={closeInfo}>
            <CloseIcon fontSize='large'/>
        </IconButton>
        <div style={{ position: 'absolute', margin:'30px'}}>
        <ChooseLng/>
        </div>
        <h1>{t('welcome')}</h1>
        <div style={infoBox}>
            
        <div></div>
            
            <div style={intro}>
            <h4>{t('intro')}</h4>
            <h3>{t('userManualTitle')}</h3>
            <h4>{t('howToStart')}</h4><img src={start} alt='Start point' style={{position:'absolute', left:'8%',top:'22%',borderRadius:'5px',height:'50px'}}/>
            <h4>{t('howToCompleteRoute')}</h4><img src={stop} alt='End point' style={{position:'absolute', left:'8%',top:'34%',borderRadius:'5px',height:'50px'}}/>
            <h4>{t('movePoints')}</h4>
            <h4>{t('howToRestart')}</h4>
            <h4>{t('howToExploreOtherRoutes')}</h4><img src={intermediate} alt='intermediate point' style={{position:'absolute', left:'8%',top:'calc(68% + 20px)',borderRadius:'5px',height:'50px'}}/>
            <h4>{t('adjustStartTime')}</h4>
            
            </div>
        </div>
        
        
        </div></>
    
  )
}

export default InfoPage