 
import { getRoute } from "./weatherRoute";
import {point} from "./point"


export function addPoint(points,number,map){
  const canvas = map.getCanvasContainer();
  const coords = points[number];
  let color = "#b0b0b0";
  let radius = 7;
  if(number === 0){
    color = "rgba(5,90,20,0.95)";
    radius = 9;
  }
  else if(number===1){
    color = "rgba(157,7,7,0.95)";
    radius = 9;
  }


  
  function onMove(e) {
    const coord = e.lngLat;
     
    // Set a UI indicator for dragging.
    canvas.style.cursor = 'grabbing';
     
    
    
    map.getSource(`point${number}`).setData(point(coord));
  
    }
    
     
    async function onUp(e) {

    canvas.style.cursor = '';
    
    
    // Unbind mouse/touch events
    map.off('mousemove', onMove);
    map.off('touchmove', onMove);

    if(points.length>=2){
      const coord = map.getSource(`point${number}`)._data.features[0].geometry.coordinates
      
      
        points[number] = {lng:coord[0],lat:coord[1]}; 
  

    }
    }

  
    if (map.getLayer(`point${number}`)) {
      map.getSource(`point${number}`).setData(point(coords));
    } else {
      map.addLayer({
        id: `point${number}`,
        type: 'circle',
        source: {
          type: 'geojson',
          data: {
            type: 'FeatureCollection',
            features: [
              {
                type: 'Feature',
                properties: {},
                geometry: {
                  type: 'Point',
                  coordinates: [coords.lng,coords.lat]
                }
              }
            ]
          }
        },
        paint: {
          'circle-radius': radius,
          'circle-color': color,
          
        }
      });
    }
    map.on('mousedown', `point${number}`, (e) => {
      // Prevent the default map drag behavior.
      e.preventDefault();
     

      canvas.style.cursor = 'grab';
      if (points[0]!==points[points.length-1]) {

      map.on('mousemove', onMove);
      map.once('mouseup', onUp);
      }
      });

    
}

