export function point(coords){
    const point = {
        type: 'FeatureCollection',
        features: [
          {
            type: 'Feature',
            properties: {},
            geometry: {
              type: 'Point',
              coordinates: [coords.lng,coords.lat]
            }
          }
        ]
      };
    return point;
}