
import {useEffect,useState} from 'react'
import mapboxgl from "mapbox-gl";
//import { calculateSymbolOffset } from '../lib/calculateSymbolOffset';




function Weather(props) {

    const map = props.map;
    const route = props.route;
    const points = props.points;
    const durations = props.durations;
    const [weather,setWeather] = useState(new Array(15));
    let startTime = props.startTime;

    const indexOffset = Math.floor(route.length/(points.length*2));
    let t= 1;
    
    

    useEffect(() => {
      const getWeather = async(coords,i)=>{
          
        const query = await fetch(
            `https://api.met.no/weatherapi/locationforecast/2.0/compact?lat=${coords.lat}&lon=${coords.lng}`,
            { method: 'GET' }
          );
          const json = await query.json();
          weather[i] = json;
          
          setWeather(weather);
          const time = new Date();
          if(startTime>=1){
            t=0;
          }
          const hoursSinceStart = Math.round(((time.getMinutes()*t)+(durations[i]/60))/60+startTime);
          const symbol_code =  json.properties.timeseries[hoursSinceStart].data.next_1_hours.summary.symbol_code;
          const weatherTime= new Date(json.properties.timeseries[hoursSinceStart].time);
      
          
          time.setSeconds(time.getSeconds()*t + durations[i] + (startTime*3600)+(time.getMinutes()*60*(t-1)))
          const el = document.createElement('div');
          const width = 55;
          const height = 55;
          const image = require(`../assets/svg/${symbol_code}.svg`);
          el.style.backgroundImage= `url(${image})`;
          
          
          el.className = 'marker'
          el.style.width = `${width}px`;
          el.style.height = `${height}px`;
          el.style.backgroundSize = '100%';
      
          let min = '0';
          if(time.getMinutes()<=9){
             min  = '0' + time.getMinutes();
          }
          else{
              min = time.getMinutes();
          }
          const popup = new mapboxgl.Popup().setText(
              `Her vil du passere ca. ${time.getHours()}:${min} og vi bruker værvarselet for ${weatherTime.getHours()}:00.`
              );
          // const offset = calculateSymbolOffset(route,points[i],indexOffset)
          
          // const pointOffset =  new mapboxgl.Point(offset[0],offset[1]);{offset:pointOffset}
          new mapboxgl.Marker(el,{offset: [25,-40]}).setLngLat([points[i].lng,points[i].lat]).setPopup(popup).addTo(map)
          el.addEventListener('click',(e)=>{
      
              const popups = document.getElementsByClassName("mapboxgl-popup");
      
          if (popups.length) {
      
              popups[0].remove();
      
          }
              new mapboxgl.Popup({ offset: [25,-40]})
              .setLngLat([points[i].lng,points[i].lat])
              .setText(
                  `Her vil du passere ca. ${time.getHours()}:${min} og vi bruker værvarselet for ${weatherTime.getHours()}:00.`
              )
              .addTo(map);
              e.stopPropagation()});
      }
      weather.splice(0,weather.length);
      setWeather(weather)
      
      for(let i = 0;i<points.length;i++ ) {
        getWeather(points[i],i);
      };

      if(map){
      const cc = map.getContainer();
      const els = cc.getElementsByClassName('marker');
      const arr = Array.prototype.slice.call( els )
      
      for(let item of arr){
        item.remove();
      }
    }
    }, [points,durations])


    useEffect(() => {
      if(map){
      const cc = map.getContainer();
      const els = cc.getElementsByClassName('marker');
      const arr = Array.prototype.slice.call( els )
      for(let i=0; i<weather.length;i++){
        if(startTime>=1){
          t=0;
        }
        const time = new Date();
        const hoursSinceStart = Math.round(((time.getMinutes()*t)+(durations[i]/60))/60+startTime);
        const symbol_code =  weather[i].properties.timeseries[hoursSinceStart].data.next_1_hours.summary.symbol_code
        const weatherTime= new Date(weather[i].properties.timeseries[hoursSinceStart].time)  
        
        time.setSeconds(time.getSeconds()*t + durations[i] + (startTime*3600)+(time.getMinutes()*60*(t-1)))
        const image = require(`../assets/svg/${symbol_code}.svg`);
        arr[i].style.backgroundImage= `url(${image})`;
        let min = '';
          if(time.getMinutes()<=9){
             min  = '0' + time.getMinutes();
          }
          else{
              min = time.getMinutes();
          }
          const popup = new mapboxgl.Popup({ offset: 25 }).setText(
              `Her vil du passere ca. ${time.getHours()}:${min} og vi bruker værvarselet for ${weatherTime.getHours()}:00.`
              );
          
          new mapboxgl.Marker(arr[i],{anchor:'bottom-left'}).setLngLat([points[i].lng,points[i].lat]).setPopup(popup).addTo(map)
          arr[i].addEventListener('click',(e)=>{
      
              const popups = document.getElementsByClassName("mapboxgl-popup");
      
          if (popups.length) {
      
              popups[0].remove();
      
          }
              new mapboxgl.Popup({ offset: [25,-40]})
              .setLngLat([points[i].lng,points[i].lat])
              .setText(
                  `Her vil du passere ca. ${time.getHours()}:${min} og vi bruker værvarselet for ${weatherTime.getHours()}:00.`
              )
              .addTo(map);
              e.stopPropagation()});
      
      }
    }
      
    }, [startTime])
    
    

    
    
    
}

export default Weather